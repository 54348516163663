<script setup lang="ts">
  import { computed } from 'vue'
  import DashCircleIcon from '@/assets/icons/DashCircle.svg'

  const props = withDefaults(
    defineProps<{
      value: string | undefined | null
      valuePlaceholder?: string
      valueHidden?: boolean
      label: string
      labelHidden?: boolean
      iconHidden?: boolean
      forceEmptyState?: boolean
    }>(),
    {
      valuePlaceholder: undefined,
      valueHidden: false,
      labelHidden: false,
      iconHidden: false,
    }
  )

  defineSlots<{
    icon: (props: { assigned: boolean }) => unknown
    value: (props: Record<string, never>) => unknown
  }>()

  const isAssigned = computed(() =>
    props.forceEmptyState ? false : props.value != null
  )
</script>

<template>
  <div class="flex items-center gap-3 overflow-hidden">
    <div
      v-if="!iconHidden"
      class="grid h-[--gc-icon-size,2rem] w-[--gc-icon-size,2rem] shrink-0 place-items-stretch"
      data-popper-target
      data-testid="base-assignment-icon-icon"
    >
      <DashCircleIcon
        v-show="!isAssigned"
        class="col-[1] row-[1] h-full w-full"
      />
      <div
        class="col-[1] row-[1] grid place-items-center rounded-full"
        :class="isAssigned ? 'bg-cyan/40 text-cyan ' : ' text-white-var/50'"
      >
        <slot :assigned="isAssigned" name="icon" />
      </div>
    </div>
    <p
      v-if="value || valuePlaceholder"
      class="text-gray-var-300 truncate"
      :class="{
        'sr-only': labelHidden && valueHidden,
      }"
      data-testid="base-assignment-icon-content"
    >
      <span
        :class="{
          'sr-only': labelHidden,
        }"
      >
        {{ label }}
      </span>
      <span>&nbsp;</span>
      <slot name="value">
        <span
          :class="[
            isAssigned ? 'text-white-var' : 'text-gray-var-600',
            {
              'sr-only': valueHidden,
            },
          ]"
        >
          {{ value ?? valuePlaceholder ?? '' }}
        </span>
      </slot>
    </p>
  </div>
</template>
